
import { defineComponent } from "vue";
import list from "@/components/common/list.vue";
import InterestList from "@/components/follow/InterestList.vue";
export default defineComponent({
  name: "Interest",
  components: {
    list,
    InterestList,
  },
});
