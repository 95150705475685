
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import dateFormat from "@/utils/dateFormat";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
import Empty from "../common/Empty.vue";
export default defineComponent({
  components: { Empty },
  name: "Follow",
  props: {
    follow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const nativeData = computed(() => {
      return props.follow.data.slice(0);
    });

    const router = useRouter();
    function followWithInterest(userid: string, index: number) {
      const data = qs.stringify({
        userid,
      });
      axios
        .post("/M/BBSServer/Follow", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          nativeData.value[index].isMyGuan = true;
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function unFollowWithInterest(userid: string, index: number) {
      const data = qs.stringify({
        userid,
      });
      axios
        .post("/M/BBSServer/CancelFollow", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            nativeData.value[index].isMyGuan = false;
            Toast({
              type: "success",
              title: res.data.msg,
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function onClick(isFocus: boolean, index: number, id: string) {
      if (isFocus) {
        followWithInterest(id, index);
      } else {
        unFollowWithInterest(id, index);
      }
    }

    return {
      dateFormat,
      onClick,
      nativeData,
    };
  },
});
