<template>
  <list url="/M/Personal/interest" :showLoading="true" :show="true">
    <template v-slot:default="data">
      <interest-list :follow="{...data,type:'Interest'}"></interest-list>
    </template>
  </list> 
 
</template>

<script lang="ts">
import { defineComponent } from "vue";
import list from "@/components/common/list.vue";
import InterestList from "@/components/follow/InterestList.vue";
export default defineComponent({
  name: "Interest",
  components: {
    list,
    InterestList,
  },
});
</script>

